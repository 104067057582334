<template>
  <div>
    <el-form
      ref="nodeForm"
      :rules="rules"
      label-position="top"
      label-width="100px"
      :model="nodeToBind"
      :hide-required-asterisk="false"
    >
      <el-row type="flex">
        <el-col :span="24">
          <el-form-item prop="node_name" :label="__('Name')" id="nodeName">
            <el-input v-model="nodeToBind.node_name"></el-input>
          </el-form-item>
        </el-col>
      </el-row>

      <el-row type="flex">
        <el-col :span="24" style="min-width:300px">
          <el-form-item
            prop="holiday_schedule"
            :label="__('Select holiday schedule')"
          >
            <el-select
              v-model="holidayScheduleSelection"
              multiple
              v-loading="isLoading"
              default-first-option
              filterable
              :placeholder="__('Please select a holiday schedule')"
              style="width: 100%;"
            >
              <el-option
                v-for="schedule in holidayScheduleList"
                :key="schedule.schedule_id"
                :label="schedule.schedule_name"
                :value="schedule.schedule_id"
                :disabled="schedule.is_archived"
              >
              </el-option>
            </el-select>
          </el-form-item>
        </el-col>
      </el-row>
      <el-row type="flex">
        <el-col :span="24" style="min-width:300px">
          <el-form-item
            :label="__('If Holiday, Goto Node')"
            prop="holiday_node"
            class="is-required"
          >
            <goto-node-condition
              v-model="trueCondition"
              condition-keyword="True"
              :goto-options="generateGotoOptions"
              style="width: 100%;"
            />
          </el-form-item>
        </el-col>
      </el-row>
      <el-row type="flex">
        <el-col :span="24" style="min-width:300px">
          <el-form-item
            :label="__('Otherwise Goto Node')"
            prop="otherwise"
            class="is-required"
          >
            <goto-node-condition
              v-model="otherwiseCondition"
              condition-keyword="Otherwise"
              :goto-options="generateGotoOptions"
              style="width: 100%;"
            />
          </el-form-item>
        </el-col>
      </el-row>
      <el-row type="flex">
        <el-col :span="24" style="min-width:300px">
          <el-form-item
            prop="calendar_node.data.variable.variable_id"
            :label="__('Assign matched holiday schedule to variable')"
          >
            <create-or-select
              :items="generateVariableOptions"
              label="variable_name"
              value="variable_id"
              :current_select="responseVariableSelection"
              :placeholder="__('Select variable or type a new name')"
              :new-item-message="__('new variable')"
              style="width: 100%;"
              @change="handleVariableSelectionChange"
            />
          </el-form-item>
        </el-col>
      </el-row>
    </el-form>
  </div>
</template>

<script>
import BaseNode from "../BaseNode";
import _ from "lodash";
import { NODE_TYPES } from "@/constants/nodes";
import CreateOrSelect from "../components/CreateOrSelect";
import GotoNodeCondition from "../components/GotoNodeCondition";
import { mapActions, mapGetters, mapState } from "vuex";
import {
  filterRowsIfSomeKeyValueIsAbsent,
  findKeywordCondition
} from "@/utils/collection";

export default {
  mixins: [BaseNode],
  components: {
    GotoNodeCondition,
    CreateOrSelect
  },
  data() {
    const validateOtherwiseNode = (rule, value, callback) => {
      const otherwiseNode = _.find(
        this.nodeToBind.calendar_node.data.keyword_conditions.data,
        { keyword: "Otherwise" }
      );
      if (!otherwiseNode) {
        callback(__("Otherwise node cannot be empty"));
      } else {
        if (otherwiseNode.node_id === -1 && !otherwiseNode.node_name) {
          callback(__("Otherwise node cannot be empty"));
        } else {
          callback();
        }
      }
    };
    const validateHolidayNode = (rule, value, callback) => {
      const holidayNode = _.find(
        this.nodeToBind.calendar_node.data.keyword_conditions.data,
        { keyword: "True" }
      );
      if (!holidayNode) {
        callback(__("Node for holiday cannot be empty"));
      } else {
        if (holidayNode.node_id === -1 && !holidayNode.node_name) {
          callback(__("Node for holiday cannot be empty"));
        } else {
          callback();
        }
      }
    };

    const variableSelect = { variable_name: "", variable_id: -1 };
    return {
      rules: {
        otherwise: {
          validator: validateOtherwiseNode,
          trigger: "change"
        },
        holiday_node: {
          validator: validateHolidayNode,
          trigger: "change"
        }
      },
      $variableSelect: variableSelect,
      holidaySchedules: []
    };
  },
  computed: {
    ...mapState("holidayschedules", {
      accountHolidaysMap: state => state.accountHolidaysMap,
      isLoading: state => state.loading
    }),

    ...mapGetters("holidayschedules", {
      holidays: "holidays"
    }),

    generateVariableOptions() {
      return _.filter(this.variables, variable => {
        return ![
          "array",
          "audio",
          "system",
          "payment",
          "secure",
          "xsip"
        ].includes(variable.variable_type);
      });
    },

    responseVariable() {
      return this.$data.$variableSelect;
    },
    responseVariableSelection() {
      if (!this.responseVariable) {
        return "";
      } else {
        const { variable_id, variable_name } = this.responseVariable;
        return variable_id === -1 ? variable_name : variable_id;
      }
    },
    holidayScheduleList() {
      return this.holidays;
    },
    holidayScheduleSelection: {
      get: function() {
        return _.map(this.holidaySchedules, "schedule_id");
      },
      set: function(val) {
        this.holidaySchedules = _.map(val, schedule_id => {
          return {
            schedule_id: schedule_id
          };
        });
        this.$set(
          this.nodeToBind.calendar_node.data.holiday_schedule,
          "data",
          this.holidaySchedules
        );
      }
    },
    allKeywordConditions() {
      return _.isEmpty(this.nodeToBind.calendar_node.data.keyword_conditions)
        ? { data: [] }
        : {
            data: _.map(
              this.nodeToBind.calendar_node.data.keyword_conditions.data,

              keywordCondition => {
                keywordCondition["error"] = "";
                if (
                  keywordCondition.node_id === -1 &&
                  !this.attemptedToSubmit
                ) {
                  keywordCondition["error"] = !_.map(
                    this.getValidNodes,
                    "node_name"
                  ).includes(keywordCondition.node_name)
                    ? ""
                    : __("Node ':nodeName' cannot be used here", {
                        nodeName: keywordCondition.node_name
                      });
                }
                if (
                  keywordCondition.node_id === -1 &&
                  keywordCondition.node_name.toLowerCase() === "disconnect"
                ) {
                  keywordCondition["error"] =
                    " '" +
                    `${keywordCondition.node_name}` +
                    "' " +
                    __("cannot be used as node name");
                }

                keywordCondition["keyword_error"] = "";
                keywordCondition["msg"] = !keywordCondition.error
                  ? keywordCondition["msg"] || ""
                  : "";

                return keywordCondition;
              }
            )
          };
    },

    /**
     * method to do extra checks to validate form, it cannot be handled by the element UI form validations
     * @returns {boolean}
     */
    formHasErrors() {
      const { data: keyword_conditions } = this.allKeywordConditions;

      return (
        _.some(keyword_conditions, condition => {
          return condition.error && condition.error.length;
        }) ||
        _.some(keyword_conditions, condition => {
          return condition.keyword_error && condition.keyword_error.length;
        })
      );
    },

    trueCondition: {
      get: function() {
        const { data: keyword_conditions } = this.allKeywordConditions;
        return _.isEmpty(keyword_conditions)
          ? {}
          : findKeywordCondition(_.cloneDeep(keyword_conditions), "True");
      },
      set: function(trueCondition) {
        if (
          // append true node to keyword conditions only if true node content changes
          !_.isEqual(this.trueCondition, trueCondition)
        ) {
          let { data: keyword_conditions } = this.allKeywordConditions;
          if (!_.isEmpty(keyword_conditions)) {
            let index = _.findIndex(keyword_conditions, function(condition) {
              return condition.keyword === trueCondition.keyword;
            });
            if (index !== -1) {
              keyword_conditions.splice(index, 1);
            }
          } else {
            keyword_conditions = [];
          }
          keyword_conditions.push(trueCondition);
          this.$set(
            this.nodeToBind.calendar_node.data.keyword_conditions,
            "data",
            keyword_conditions
          );
        }
      }
    },
    otherwiseCondition: {
      get: function() {
        const { data: keyword_conditions } = this.allKeywordConditions;
        return _.isEmpty(keyword_conditions)
          ? {}
          : findKeywordCondition(_.cloneDeep(keyword_conditions), "Otherwise");
      },
      set: function(otherwiseCondition) {
        if (
          // append otherwise node to keyword conditions only if otherwise node content changes
          !_.isEqual(this.otherwiseCondition, otherwiseCondition)
        ) {
          let { data: keyword_conditions } = this.allKeywordConditions;
          if (!_.isEmpty(keyword_conditions)) {
            let index = _.findIndex(keyword_conditions, function(condition) {
              return condition.keyword === otherwiseCondition.keyword;
            });
            if (index !== -1) {
              keyword_conditions.splice(index, 1);
            }
          } else {
            keyword_conditions = [];
          }
          keyword_conditions.push(otherwiseCondition);
          this.$set(
            this.nodeToBind.calendar_node.data.keyword_conditions,
            "data",
            keyword_conditions
          );
        }
      }
    }
  },
  methods: {
    ...mapActions("holidayschedules", {
      forceGetHolidaySchedules: "forceGetHolidaySchedules"
    }),
    handleVariableSelectionChange(option) {
      this.newVariableCreated = option.value === -1;
      // console.log("b", option.value);
      this.$set(this.$data.$variableSelect, "variable_name", option.label);
      this.$set(this.$data.$variableSelect, "variable_id", option.value);
    },
    cleanUpNodeToPrepareForSubmit() {
      const nodeToCleanUp = _.cloneDeep(this.nodeToBind);
      // cleanup keyword_matches
      const keywordConditions = _.map(
        filterRowsIfSomeKeyValueIsAbsent(
          _.map(this.allKeywordConditions.data, keywordCondition => ({
            ...keywordCondition,
            keyword: keywordCondition.keyword.toString().trim(),
            node_name: keywordCondition.node_name.toString().trim()
          })),
          "keyword,node_name"
        ),
        condition => {
          const { keyword, node_id, node_name } = condition;
          return {
            keyword,
            node_id,
            node_name
          };
        }
      );

      this.$set(
        nodeToCleanUp.calendar_node.data.keyword_conditions,
        "data",
        keywordConditions
      );
      this.$set(
        nodeToCleanUp.calendar_node.data.holiday_schedule,
        "data",
        this.holidaySchedules
      );
      this.$set(
        nodeToCleanUp.calendar_node.data,
        "variable",
        this.responseVariable
      );
      return nodeToCleanUp;
    },
    cleanUpNode() {
      if (!this.formHasErrors) {
        this.nodeToBind = this.cleanUpNodeToPrepareForSubmit();
        this.createOrEditNode();
      } else {
        this.toggleNodeSubmit(false);
      }
    }
  },
  created() {
    if (!this.nodeToBind.node_id || _.isEmpty(this.nodeToBind.calendar_node)) {
      // console.log(this.nodeToBind);
      this.$set(this.nodeToBind, "calendar_node", {});
      this.$set(this.nodeToBind.calendar_node, "data", {});
      this.$set(this.nodeToBind.calendar_node.data, "holiday_schedule", {});
      this.$set(
        this.nodeToBind.calendar_node.data.holiday_schedule,
        "data",
        []
      );

      this.$set(this.nodeToBind.calendar_node.data, "variable", null);

      if (_.isEmpty(this.nodeToBind.calendar_node.data.keyword_conditions)) {
        this.$set(this.nodeToBind.calendar_node.data, "keyword_conditions", {});
        this.$set(
          this.nodeToBind.calendar_node.data.keyword_conditions,
          "data",
          []
        );
      }

      this.$set(this.nodeToBind, "node_type", NODE_TYPES.CALENDAR.NODE_TYPE);
    } else {
      this.$data.$variableSelect = this.nodeToBind.calendar_node.data.variable;
      this.holidaySchedules = this.nodeToBind.calendar_node.data.holiday_schedule.data;
    }
  },
  mounted() {
    this.forceGetHolidaySchedules({ fetch_all: 1 });
  }
};
</script>

<style scoped lang="scss">
@import "~@/styles/node_common.scss";
.tabPane {
  max-height: 52vh;
  padding-right: 30px;
}
</style>
